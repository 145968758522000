.count-card {
    width: 6rem;
    min-height: 5rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    texttransform: "none";

    .count {
        font-size: 2rem;
    }
}

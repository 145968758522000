.layout {
  display: flex;
  overflow: hidden;
}

.main-content {
  overflow: auto;
  // background: #e3f2ff;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  width: -webkit-fill-available;
  height: calc(100vh - 2rem);
  // padding: 5rem 1rem 1rem 1rem;
}

.top-bar-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  /* height: 3rem; */
  padding: 0.5rem;
  margin: 1rem 2rem;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0px;
  left: auto;
  right: 0px;
  color: rgb(0, 0, 0);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  border-radius: 85px;
  background: #c1c1c163;
  
  .top-bar-btn {
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:100%;
    background: linear-gradient(145deg, #c1c1c1, #e5e5e5);
  }
  
  .notification-btn {
    color: #b948ff;
  }
  .logout-btn {
    color: #d61414;
  }
  .notification-btn:hover {
    background: linear-gradient(145deg, #fc77fc, #ae00ff);
    color: #ffffff;
  }
  .logout-btn:hover {
    background: linear-gradient(145deg, #ff8471,#B92D16);
    color: #ffffff;
  }
}

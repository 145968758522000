.sidebar-container {
  width: 16rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--bg-primary);
  z-index: 999;
  background: white;

  .logo-section {
    height: 6rem;
    font-size: 0.75rem;
    font-weight: 900;
    color: #00378b;
    display: flex;
    align-items: center;
    // border-bottom: 1px dotted var(border-primary);
    // border-bottom: 1px dotted #afafaf;

    .logo {
      height: inherit;
    }
  }

  .menu-items {
    height: 100%;
    height: -webkit-fill-available;
    overflow: auto;
    background: linear-gradient(53deg, #bdbdbd, #fff, white);
    padding-top: 5rem;
  }

  .settings-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #8a2be2;
    padding: 0.25rem;
  }
}
.compact-menu-button {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.25rem;
  background: #8a2be270;
  border-radius: 0 55% 0 0;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  z-index: 999;
}

.sidebar-item {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  text-decoration: none;
  color: #77819e;
  border-radius: 0.5rem;
}
.selected {
  background: #007ce2;
  color: #ffffff;
}
.sidebar-item:hover:not(.selected) {
  cursor: pointer;
  background: #e2e8ed;
  color: #0003ab;
}

.info-section {
    padding      : 1rem;
    border-radius: 1rem;
    background   : linear-gradient(165deg, #a5d2f8, #ffffff00, #ffffff00);
    box-shadow   :  26px 26px 46px #ffffff;
    padding-bottom: 0;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: -10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}